<template>
<v-app>
    <v-main style="padding: 0; margin-top: -15vh !important; padding: 30px">
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <loading-overlay :loading="loading" />
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col xl="12" lg="12" md="12" sm="12" xs="12" align="center" justify="center"><h2 class="mb-5">PROCESSING</h2></v-col>
              <v-col align="center" justify="center">
                <v-img src="/img/processing.gif" alt="Please Wait..." contain max-height="30vh" />
              </v-col>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import LoadingOverlay from '@/views/component/LoadingOverlay.vue';
import API from '@/api/API'

export default {
    name: 'ReroutePayment',
    components: {
        LoadingOverlay
    },
    async mounted() {
      if (location.search.trim() !== '') {
        console.log('has params')
        let query = decodeURIComponent(location.search.substring(1))
        let queries = query.split('&')
          .map(q => {
              let key = q.substring(0, q.indexOf('='));
              let value = q.substring(q.indexOf('=') + 1);
              return {
                  key,
                  value,
              }
          });
        for (var q in queries) {
          if(queries[q].key == 'redirect') {
            window.parent.postMessage(JSON.stringify({url: `${queries[q].value}`}), '*')
            break;
          } else if(queries[q].key == 'LBPRefNum' && 'LBPConfDate' && 'LBPConfNum' && 'ErrorCode') {
            this.loading = true

            try{

              //-- check url params--//
              let lbpUrlParams = new URLSearchParams(location.search);

              //-- set key values to variables --//
              let LBPRefNumVal = lbpUrlParams.get('LBPRefNum');
              let MerchantRefNumVal = lbpUrlParams.get('MerchantRefNum');
              let LBPConfDateVal = lbpUrlParams.get('LBPConfDate');
              let TrxnAmountVal = lbpUrlParams.get('TrxnAmount');
              let LBPConfNumVal = lbpUrlParams.get('LBPConfNum');
              let ErrorCodeVal = lbpUrlParams.get('ErrorCode');
              let ChecksumVal = lbpUrlParams.get('Checksum');
              // let CRNVal = lbpUrlParams.get('CRN');

              //-- set variable for params --//
              var verify = undefined

              // set key values var to const params
              const params = {
                LBPRefNum: LBPRefNumVal,
                MerchantRefNum: MerchantRefNumVal,
                LBPConfDate: LBPConfDateVal,
                TrxnAmount: TrxnAmountVal,
                LBPConfNum: LBPConfNumVal,
                ErrorCode: ErrorCodeVal,
                Checksum: ChecksumVal,
                CRN: MerchantRefNumVal,
              }

              // -- initiate API call --//
              verify = await API.getCallbackUrl(params)

              if (verify) {
                if(verify.statusCode) {
                  if(verify.successUrl) {
                    window.location.replace(verify.successUrl)
                  } else if (verify.failUrl) {
                    window.location.replace(verify.failUrl)
                  } else {
                    this.loading = false
                    console.log("Error: " + verify.statusCode + " - " + verify.message)
                  }
                }
              } else {
                this.loading = false
                console.log("Error: " + verify.statusCode + " - " + verify.message)
              }
            } catch(error) {
              this.loading = false
              console.log("callback API Error: " + error)
            }
            break;
          }
        }
      }
    },
}
</script>
